<template>
  <div class="shelf-search">
    <div class="shelf-search__content">
      <div class="ant-row flex flex-wrap justify-center">
        <div class="ant-col">
          <div class="shelf-search__item">
            <div class="shelf-search__item-image">
              <img src="/images/PGCategory.jpeg" alt="" />
              <span class="shelf-search__title">Category</span>
            </div>
            <label>Select a category</label>
            <div class="position-r">
              <Skeleton :borderRadius="4" v-if="shelfCategoryLoader"></Skeleton>
              <a-select
                class="fullWidth"
                option-filter-prop="children"
                show-search
                placeholder="Select a category"
                :key="Math.random()"
                :default-value="chosenShelfCategory"
                @change="chosenShelfCategory = $event"
              >
                <a-select-option
                  v-for="item in shelfCategoryInfo"
                  :key="item.CetegoryName"
                  :value="item.CetegoryName"
                >
                  {{ item.CetegoryName }}
                </a-select-option>
              </a-select>
            </div>
          </div>
        </div>
        <div class="ant-col">
          <div class="shelf-search__item">
            <div class="shelf-search__item-image">
              <img src="/images/PGCountry.jpeg" alt="" />
              <span class="shelf-search__title shelf-search__title--black"
                >Market</span
              >
            </div>
            <label>Select a market</label>
            <div class="position-r">
              <Skeleton :borderRadius="4" v-if="shelfMarketLoader"></Skeleton>
              <a-select
                class="fullWidth"
                option-filter-prop="children"
                show-search
                :key="Math.random()"
                placeholder="Select a market"
                :default-value="chosenShelfCountry"
                @change="chosenShelfCountry = $event"
              >
                <a-select-option
                  v-for="item in shelfMarketInfo"
                  :key="item.CountryName"
                  :value="item.CountryName"
                >
                  {{ item.CountryName }}
                </a-select-option>
              </a-select>
            </div>
          </div>
        </div>
      </div>
      <div class="ant-row flex flex-wrap justify-center shelf-search__cta">
        <a-button
          type="primary"
          @click="
            $router.push({
              path: '/shelf-library',
              query: {
                category: chosenShelfCategory,
                market: chosenShelfCountry,
              },
            })
          "
          >View
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";
import Skeleton from "@/components/general/Skeleton.vue";

export default {
  name: "ShelfSearch",
  components: {
    Skeleton,
  },
  props: {
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      shelfLibraryInfo: [],
      shelfInfoLoader: false,
      chosenShelfCategory: "All",
      chosenShelfCountry: "All",
      shelfCategoryInfo: [],
      shelfMarketInfo: [],
      shelfLoader: true,
      shelfCategoryLoader: false,
      shelfMarketLoader: false,
    };
  },
  computed: {},
  methods: {
    shelfCategory() {
      this.shelfCategoryLoader = true;
      const getShelfCategory = new Promise((resolve) => {
        wsUtils.GetShelfCategory(
          {
            userId: this.user.EncrypteduserID,
          },
          resolve
        );
      });

      getShelfCategory.then((data) => {
        this.shelfCategoryInfo = data;
        this.shelfCategoryLoader = false;
      });
    },
    shelfMarket() {
      this.shelfMarketLoader = true;
      const getShelfMarket = new Promise((resolve) => {
        wsUtils.GetShelfMarket(
          {
            userId: this.user.EncrypteduserID,
          },
          resolve
        );
      });

      getShelfMarket.then((data) => {
        this.shelfMarketInfo = data;
        this.shelfMarketLoader = false;
      });
    },
  },
  activated() {
    this.user = jsUtils.getUserInfo();
    this.shelfCategory();
    this.shelfMarket();
  },
};
</script>

<style lang="scss" scoped>
.shelf-search {
  background: #f9f9f9;
  text-align: left;
  &__content {
    padding: 120px 77px 0;
    .ant-row {
      margin-bottom: 30px;
    }
    .ant-col {
      padding: 0 4.5%;
      width: 33.33%;
      margin-bottom: 40px;
      @media screen and (max-width: 1500px) {
        padding: 0 2.5%;
      }
    }
  }
  &__item {
    height: 100%;
    ::v-deep .ant-select {
      .ant-select-selection--single {
        height: 40px;
        border-color: #e8e8e8;
        border-radius: 0;
        .ant-select-selection__rendered {
          line-height: 38px;
        }
      }
    }
    label {
      font-size: 12px;
      color: #858585;
    }
  }
  &__item-image {
    background: #fff;
    padding: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 25px;
    padding-bottom: 46.7%;
    position: relative;
    img {
      max-width: 100%;
      border-radius: 6px;
      position: absolute;
      left: 5px;
      top: 5px;
      width: calc(100% - 10px);
      height: calc(100% - 10px);
      object-fit: cover;
    }
  }
  &__title {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 84%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 4px;
    font-weight: 700;
    font-size: 26px;
    color: #fff;
    background: rgba(26, 59, 166, 0.8);
    box-shadow: 0 0 11px rgba(26, 59, 166, 1);
    &--black {
      background: rgba(0, 0, 0, 0.54);
      box-shadow: 0 0 11px rgba(0, 0, 0, 0.44);
    }
    @media screen and (max-width: 1400px) {
      font-size: 22px;
    }
  }
  &__item-info {
    margin: 0;
    padding: 0 0 0 10px;
    li {
      margin-bottom: 4px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__cta {
    .ant-btn {
      min-width: 127px;
      height: 36px;
      margin: 0 8px;
    }
  }
}
</style>
