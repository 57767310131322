<template>
  <div class="products">
    <a-breadcrumb class="breadcrum">
      <a-breadcrumb-item
        ><a href="/">{{ client.ClientName }}</a></a-breadcrumb-item
      >
      <a-breadcrumb-item>Products</a-breadcrumb-item>
    </a-breadcrumb>
    <h3>Click product below to get started!</h3>
    <section class="productGrids ready">
      <div
        class="product"
        v-for="product in readyProducts"
        :key="product.TypeId"
      >
        <div class="icon" @click="selectProduct(product)">
          <img :src="product.Icon" alt="product.name" />
        </div>
        <p>
          {{ product.Name }}
        </p>
      </div>
    </section>
    <h4>
      <hr />
      <p>
        <span class="cursive"> coming soon </span>
      </p>
    </h4>
    <section class="productGrids upcoming">
      <div
        class="product"
        v-for="product in upcomingProducts"
        :key="product.TypeId"
      >
        <div class="icon">
          <img :src="product.Icon" alt="product.Icon" />
        </div>
        <p>
          {{ product.Name }}
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import * as jsUtils from "@/utils/jsUtils.js";
import * as wsUtils from "@/utils/wsUtils.js";
import { mutations } from "@/utils/store.js";
export default {
  name: "Products",
  data() {
    return {
      client: {},
      user: null,
      products: [],
      projectID: 0,
    };
  },
  computed: {
    readyProducts() {
      return this.products.filter((product) => product.Status === "1");
    },
    upcomingProducts() {
      return this.products.filter((product) => product.Status === "2");
    },
  },
  methods: {
    selectProduct(product) {
      switch (product.TypeId) {
        case 92:
        case 8:
        case 97:
        case 98:
        case 99:
        case 101:
        case 102:
          this.createVidProject(product);
          break;
        case 2:
          this.$router.push({ name: "image-ads" });
          break;
        case 93:
          this.createPackTestProject(product);
          break;
        default:
          break;
      }
    },
    createVidProject(product) {
      let routeName;
      switch (product.TypeId) {
        case 97:
          routeName = "vid-yt-setting";
          break;
        case 98:
          routeName = "vid-ua-setting";
          break;
        case 99:
          routeName = "vid-yt-ua-setting";
          break;
        case 101:
          routeName = "vid-tiktok-setting";
          break;
        case 102:
          routeName = "vid-reticle-setting";
          break;
        default:
          routeName = "vid-setting";
          break;
      }

      mutations.setSurveyPrice(product.TypeId, "SurveyType");
      const brandHelthType = product.TypeId === 92;
      const createSurvey = new Promise((resolve) => {
        wsUtils.InsertSurveyData1(
          {
            SurveyTypeId: product.TypeId,
            ProjectID: Number(this.projectID),
            UserID: window.userID,
          },
          resolve
        );
      });
      createSurvey.then((data) => {
        let surveyPriceSetData = {
          SurveyId: data.SurveyId,
          SurveyType: product.TypeId,
          ClickSpex: true,
          Stims: brandHelthType ? [] : [{ duration: 100 }],
          Question: [],
          Audience: {
            sampleSize: 200,
            categoryUser: 0,
            genPop: 1,
          },
        };

        jsUtils.setSurveyTypeId(product.TypeId);
        jsUtils.setSurveyPrice(surveyPriceSetData);
        jsUtils.setSurveyUnEncryptId(data.UnEncryptSId);
        jsUtils.setRunSurveyMode(true);

        mutations.setSurveyPrice(data.SurveyId, "SurveyId");
        this.$router.push({
          name: routeName,
          params: { id: data.SurveyId },
        });
      });
    },
    createPackTestProject(product) {
      mutations.setSurveyPrice(product.TypeId, "SurveyType");
      const createSurvey = new Promise((resolve) => {
        wsUtils.InsertSurveyPacktest(
          {
            SurveyTypeId: product.TypeId,
            ProjectID: Number(this.projectID),
            UserID: window.userID,
          },
          resolve
        );
      });
      createSurvey.then((data) => {
        let surveyPriceSetData = {
          SurveyId: data.SurveyId,
          SurveyType: product.TypeId,
          ClickSpex: true,
          Stims: [],
          Question: [],
          Audience: {
            sampleSize: 200,
            categoryUser: 0,
            genPop: 1,
          },
        };

        let packTestPriceSetData = {
          surveyId: data.SurveyId,
          explicitSamplePerDeepDive: 100,
          legs: 1,
          deepDive: 1,
          compDeepDive: 0,
          comObjects: 1,
        };

        jsUtils.setSurveyTypeId(product.TypeId);
        jsUtils.setSurveyPrice(surveyPriceSetData);
        jsUtils.setSurveyUnEncryptId(data.UnEncryptSId);
        jsUtils.setRunSurveyMode(true);
        jsUtils.setPackTestPrice(packTestPriceSetData);

        mutations.setSurveyPrice(data.SurveyId, "SurveyId");
        this.$router.push({
          name: "pack-test-setting",
          params: { id: data.SurveyId },
        });
      });
    },
  },
  mounted() {
    this.client = jsUtils.getCurClient();
    this.user = jsUtils.getUserInfo();
    this.projectID = parseInt(sessionStorage.getItem("pID"));
    const tyepPromise = new Promise((resolve) => {
      wsUtils.SurveyTypeFilter(
        {
          accountId: this.user.EncryptedAccountId,
        },
        resolve
      );
    });

    tyepPromise.then((data) => {
      this.products = data;
    });
  },
};
</script>

<style lang="scss" scoped>
h3 {
  margin: 30px auto 60px;
  font-size: 1.6em;
}

h4 {
  margin: 60px 20px;
  position: relative;

  hr {
    margin: 1.3em;
    position: absolute;
    left: 20px;
    right: 20px;
    z-index: -1;
  }

  p {
    font-size: 1.8em;
    text-align: center;

    span {
      background-color: white;
      padding: 0 2em;
      color: var(--iconBGGrey);
    }
  }
}

.productGrids {
  display: flex;
  column-gap: 30px;
  justify-content: center;
  flex-wrap: wrap;
  .icon {
    border-radius: 100%;

    img {
      max-width: 100%;
      transition: transform 0.3s;
    }
  }
}

.ready {
  column-gap: 50px;
  @media screen and (max-width: 980px) {
    column-gap: 30px;
  }
  .icon {
    background-color: var(--hsblue);
    width: 240px;
    height: 240px;
    padding: 10%;
    cursor: pointer;
    overflow: hidden;
    @media screen and (max-width: 980px) {
      width: 200px;
      height: 200px;
    }
  }

  .icon:hover {
    img {
      transform: scale(0.9);
    }

    box-shadow: inset 0 0 8px 0 rgba(0, 0, 0, 0.4);
    background-color: var(--hsblueDark);
  }
}

.upcoming {
  column-gap: 40px;

  .icon {
    background-color: var(--lineGrey);
    width: 150px;
    height: 150px;

    padding: 15%;
    cursor: default;
  }
}
</style>
