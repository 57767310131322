var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard"},[_c('div',{staticClass:"dashboard__content"},[_c('h1',[_vm._v("Dashboard")]),(_vm.loadingDashboard)?_c('p',{staticClass:"flex items-center justify-center dashboard__loader"},[_c('i',{staticClass:"circle-loader"}),_vm._v("Loading dashboard... ")]):_c('div',[_c('div',{staticClass:"flex ant-row"},[_c('div',{staticClass:"ant-col ant-col-8 project-status"},[_c('div',{staticClass:"flex dashboard__box",style:({ height: _vm.boxHeight + 'px' })},[_c('div',{staticClass:"flex justify-between mb-20"},[_c('h2',{staticClass:"mb-0"},[_vm._v("Project Status")]),_c('div',{staticClass:"flex items-center dashboard__box-filter"},[_c('label',[_vm._v("Filter")]),_c('a-select',{staticClass:"fullWidth",attrs:{"option-filter-prop":"children","show-search":"","placeholder":"Please select","default-value":"YEAR"},on:{"change":_vm.projectSummary}},_vm._l((_vm.projectFilter),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.title)+" ")])}),1)],1)]),_c('a-table',{staticClass:"custom-table custom-table--dashboard",attrs:{"tableLayout":"auto","columns":_vm.columns,"row-key":function (record) { return record.UserClientName; },"data-source":_vm.projectSummaryInfo,"pagination":_vm.pagination,"loading":_vm.loading},on:{"change":_vm.handleTableChange}}),_c('div',{staticClass:"flex items-end justify-end flex-1 mt-15"},[_c('a-button',{attrs:{"type":"primary","disabled":_vm.projectSummaryInfo.length === 0},on:{"click":_vm.viewProjects}},[_vm._v("View ")])],1)],1)]),(
            _vm.dashboardDetailsInfo[0] &&
            _vm.dashboardDetailsInfo[0].ShelfLibraryEnabled
          )?_c('div',{staticClass:"ant-col ant-col-8 shelf-library"},[_c('div',{staticClass:"dashboard__box dashboard__box--shelf",style:({ height: _vm.boxHeight + 'px' })},[_c('h2',[_vm._v("Shelf Library")]),_c('div',{staticClass:"flex items-center mb-10"},[_c('label',[_vm._v("Category")]),_c('a-select',{staticClass:"fullWidth",attrs:{"option-filter-prop":"children","show-search":"","placeholder":"Please select","default-value":"All"},on:{"change":function($event){(_vm.chosenShelfCategory = $event), _vm.shelfLibrary()}}},_vm._l((_vm.shelfCategoryInfo),function(item){return _c('a-select-option',{key:item.CetegoryName,attrs:{"value":item.CetegoryName}},[_vm._v(" "+_vm._s(item.CetegoryName)+" ")])}),1)],1),_c('div',{staticClass:"flex items-center"},[_c('label',[_vm._v("Market")]),_c('a-select',{staticClass:"fullWidth",attrs:{"option-filter-prop":"children","show-search":"","placeholder":"Please select","default-value":"All"},on:{"change":function($event){(_vm.chosenShelfCountry = $event), _vm.shelfLibrary()}}},_vm._l((_vm.shelfMarketInfo),function(item){return _c('a-select-option',{key:item.CountryName,attrs:{"value":item.CountryName}},[_vm._v(" "+_vm._s(item.CountryName)+" ")])}),1)],1),_c('div',{staticClass:"flex items-center justify-center dashboard__box-numbers"},[_c('div',{staticClass:"dashboard__box-numbers-item"},[_c('span',[(_vm.shelfInfoLoader)?_c('i',{staticClass:"circle-loader"}):_c('strong',[_vm._v(_vm._s(_vm.shelfLibraryInfo.length))])]),_c('span',[_vm._v("Shelves available")])]),_c('div',{staticClass:"dashboard__box-numbers-item"},[_c('span',[(_vm.shelfInfoLoader)?_c('i',{staticClass:"circle-loader"}):_c('strong',[_vm._v(_vm._s(_vm.sumProductZones))])]),_c('span',[_vm._v("Product zones")])])]),_c('div',{staticClass:"flex items-end justify-end flex-1"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
                    path: '/shelf-library',
                    query: {
                      category: _vm.chosenShelfCategory,
                      market: _vm.chosenShelfCountry,
                    },
                  })}}},[_vm._v("View ")])],1)])]):_vm._e(),(
            _vm.dashboardDetailsInfo[0] && _vm.dashboardDetailsInfo[0].SupportEnabled
          )?_c('div',{staticClass:"ant-col ant-col-8 support"},[_c('div',{staticClass:"dashboard__box dashboard__box--support",class:{ 'show-details': _vm.showSupportDetails },style:({ height: _vm.boxHeight + 'px' })},[_c('h2',{staticClass:"mb-20"},[_vm._v("Support")]),_c('div',{staticClass:"dashboard__box-support-text",attrs:{"id":"box-support-text"},domProps:{"innerHTML":_vm._s(this.dashboardDetailsInfo[0].SupportText)}}),(_vm.supportTextHeight > 220)?_c('div',{staticClass:"dashboard__box-read-more"},[_c('span',{staticClass:"dashboard__box-read-more-cta",on:{"click":function($event){_vm.showSupportDetails = !_vm.showSupportDetails}}},[_vm._v("See "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.showSupportDetails ? 'less' : 'more')}}),_c('font-awesome-icon',{attrs:{"icon":"arrow-right"}})],1)]):_vm._e()])]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }