<template>
  <div class="shelf-library">
    <div class="shelf-library__content">
      <h1>Shelf Library</h1>
      <div class="management-system__tools flex justify-between items-end">
        <div class="management-system__filters flex items-end">
          <div class="management-system__filters-item">
            <label>Category</label>
            <div class="position-r">
              <Skeleton :borderRadius="4" v-if="shelfCategoryLoader"></Skeleton>
              <a-select
                class="fullWidth"
                option-filter-prop="children"
                show-search
                placeholder="Please select"
                :default-value="chosenShelfCategory"
                :key="Math.random()"
                @change="(chosenShelfCategory = $event), setQuery()"
              >
                <a-select-option
                  v-for="item in shelfCategoryInfo"
                  :key="item.CetegoryName"
                  :value="item.CetegoryName"
                >
                  {{ item.CetegoryName }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div class="management-system__filters-item">
            <label>Market</label>
            <div class="position-r">
              <Skeleton :borderRadius="4" v-if="shelfMarketLoader"></Skeleton>
              <a-select
                class="fullWidth"
                option-filter-prop="children"
                show-search
                :key="Math.random()"
                placeholder="Please select"
                :default-value="chosenShelfCountry"
                @change="(chosenShelfCountry = $event), setQuery()"
              >
                <a-select-option
                  v-for="item in shelfMarketInfo"
                  :key="item.CountryName"
                  :value="item.CountryName"
                >
                  {{ item.CountryName }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <a-button
            class="management-system__filters-search-btn"
            type="primary"
            @click="filterShelf"
          >
            <div class="flex" v-if="shelfLoader">
              <i class="circle-loader"></i>
            </div>
            <span v-else>View</span>
          </a-button>
        </div>
      </div>

      <p
        class="flex items-center justify-center shelf-library__loader"
        v-if="shelfLibraryInfo.length === 0 && !shelfLoader"
      >
        <font-awesome-icon icon="info-circle" /> There is no Shelf for chosen
        criteria...
      </p>

      <p
        class="flex items-center justify-center shelf-library__loader"
        v-if="shelfLoader"
      >
        <i class="circle-loader"></i>Loading Shelf Library...
      </p>

      <div class="ant-row flex flex-wrap" v-else>
        <div
          class="ant-col"
          v-for="item in shelfLibraryInfo"
          :key="item.shelfID"
        >
          <div class="shelf-library-item">
            <div class="shelf-library-item__image image-loading">
              <div class="shelf-library-item__cta">
                <a-button type="text" @click="productDetails(item.shelfID)"
                  ><font-awesome-icon icon="list" /> Shelf Details</a-button
                >
                <a-button type="text" @click="storeDemo(item.cipId)"
                  ><font-awesome-icon icon="store" /> Store Demo</a-button
                >
              </div>
              <img
                :src="item.thumbImageURL"
                @error="
                  ($event.target.src = item.imageURL), createImageThumb(item)
                "
                @load="
                  $event.target.parentNode.classList.toggle(
                    'image-loading--loaded'
                  )
                "
                alt="Shelf"
              />
            </div>
            <ul class="shelf-library-item__info">
              <li>
                <strong>Project:</strong> {{ item.Name }}
                <span class="no-white-space"
                  >({{ item.numberOfProducts }} products)</span
                >
              </li>
              <li><strong>Project Date:</strong> {{ item.date }}</li>
              <li><strong>Retailer:</strong> {{ item.Retailer }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";
import Skeleton from "@/components/general/Skeleton.vue";

export default {
  name: "ShelfLibrary",
  components: {
    Skeleton,
  },
  props: {
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      shelfLibraryInfo: [],
      shelfInfoLoader: false,
      chosenShelfCategory: "All",
      chosenShelfCountry: "All",
      shelfCategoryInfo: [],
      shelfMarketInfo: [],
      shelfLoader: true,
      shelfCategoryLoader: false,
      shelfMarketLoader: false,
    };
  },
  computed: {},
  methods: {
    shelfLibrary() {
      this.shelfLoader = true;
      const getShelfLibrary = new Promise((resolve) => {
        wsUtils.GetShelfLibrary(
          {
            userId: this.user.EncrypteduserID,
            category: this.chosenShelfCategory,
            country: this.chosenShelfCountry,
          },
          resolve
        );
      });

      getShelfLibrary.then((data) => {
        this.setShelfLibraryInfo(data);
        this.shelfInfoLoader = false;
        this.shelfLoader = false;
      });
    },
    setShelfLibraryInfo(data) {
      data.forEach((element) => {
        element.thumbImageURL = element.imageURL.replace(
          /(\.[\w\d_-]+)$/i,
          "_thumb$1"
        );
      });
      this.shelfLibraryInfo = data;
    },
    shelfCategory() {
      this.shelfCategoryLoader = true;
      const getShelfCategory = new Promise((resolve) => {
        wsUtils.GetShelfCategory(
          {
            userId: this.user.EncrypteduserID,
          },
          resolve
        );
      });

      getShelfCategory.then((data) => {
        this.shelfCategoryInfo = data;
        this.shelfCategoryLoader = false;
      });
    },
    shelfMarket() {
      this.shelfMarketLoader = true;
      const getShelfMarket = new Promise((resolve) => {
        wsUtils.GetShelfMarket(
          {
            userId: this.user.EncrypteduserID,
          },
          resolve
        );
      });

      getShelfMarket.then((data) => {
        this.shelfMarketInfo = data;
        this.shelfMarketLoader = false;
      });
    },
    setQuery() {
      var data = Object.assign({}, this.$route.query);
      data["category"] = this.chosenShelfCategory;
      data["market"] = this.chosenShelfCountry;
      this.$router.push({ name: "shelfLibrary", query: data });
    },
    productDetails(id) {
      this.$router.push({
        path: "/shelf-details",
        query: {
          shelfID: id,
        },
      });
    },
    storeDemo(cipid) {
      window.open(
        `https://s3.us-east-1.amazonaws.com/simage.hotspex.com/mobileApp/shop3D/demo/labs.html?scanPath=&startDirection=1&storeConfig=https://s3.amazonaws.com/simage.hotspex.com/Projects/c${cipid}/autoJSON/shop/template.json`,
        "_blank"
      );
    },
    filterShelf() {
      (this.chosenShelfCategory = this.$route.query.category),
        (this.chosenShelfCountry = this.$route.query.market),
        this.shelfLibrary();
    },
    createImageThumb() {
      // const getThumbnailImage = new Promise((resolve) => {
      //   wsUtils.GetThumbnailImage(
      //     {
      //       CipId: item.cipId,
      //     },
      //     resolve
      //   );
      // });
      // getThumbnailImage.then(() => {});
    },
  },
  activated() {
    (this.chosenShelfCategory = this.$route.query.category || "All"),
      (this.chosenShelfCountry = this.$route.query.market || "All"),
      (this.user = jsUtils.getUserInfo());
    this.shelfLibrary();
    this.shelfCategory();
    this.shelfMarket();
  },
};
</script>

<style lang="scss" scoped>
.shelf-library {
  background: #f9f9f9;
  text-align: left;
  &__content {
    padding: 70px 77px;
    h1 {
      font-size: 23px;
      margin-bottom: 35px;
      color: #345364;
      border-bottom: 1px solid #e7e7e7;
      padding-bottom: 5px;
    }
    .ant-row {
      margin: 0 -10px;
    }
    .ant-col {
      padding: 0 10px;
      width: 33.33%;
      margin-bottom: 40px;
      @media screen and (max-width: 1400px) {
        width: 50%;
      }
    }
  }
  &__loader {
    padding: 130px 0;
    svg {
      color: #555555;
      margin-right: 5px;
    }
  }
}
</style>
